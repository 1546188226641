import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import {
  dbFormatDate,
  formatDate,
  formatDateWithoutTimeZoneConversion,
  formatToStandardDate,
  formatToStandardDateForRangeFilter,
  standardFormatToday,
} from "../../../api_calls/utils";
import DatePicker from "react-datepicker";
import { DateRange } from "@mui/icons-material";
import moment from "moment/moment";
import platformConfig from "../../../platformConfig";

const epochDate = new Date("1970-01-01");

function fromNumberToDate(dateSerialNum) {
  return new Date(epochDate.getTime() + dateSerialNum * 24 * 60 * 60 * 1000);
}

const DateRangeFilter = ({ onValueChange, vizId, fieldName, minSelectableDate, maxSelectableDate }) => {
  const startDate =
    typeof minSelectableDate !== "undefined" && minSelectableDate !== ""
      ? formatToStandardDate(minSelectableDate)
      : formatToStandardDate("2022-10-01");

  // end date will always be (today - 1day)
  const today = standardFormatToday();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  // set end date
  const endDate =
    typeof maxSelectableDate !== "undefined" && maxSelectableDate !== ""
      ? formatToStandardDate(maxSelectableDate)
      : yesterday;

  const dateToValue = (date) => {
    // Convert the date to UTC to neutralize any timezone differences
    // const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    // return Math.floor((utcDate - epochDate.getTime()) / (24 * 60 * 60 * 1000));
    const utcDate = moment(date).utc().startOf("day").valueOf();
    const epochDate = moment("1970-01-01").utc().startOf("day").valueOf();
    return Math.floor((utcDate - epochDate) / (24 * 60 * 60 * 1000));
  };

  const [selectedStartDate, setSelectedStartDate] = React.useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = React.useState(endDate);

  const [range, setRange] = useState([dateToValue(startDate), dateToValue(endDate)]);

  const handleChange = (event, newRange) => {
    setRange(newRange);
    setSelectedStartDate(formatToStandardDate(fromNumberToDate(newRange[0])));
    setSelectedEndDate(formatToStandardDate(fromNumberToDate(newRange[1])));
  };

  const handleCommittedChange = () => {
    // Use the functional form of state update to ensure the latest state values
    setRange((prevRange) => {
      const startDate = fromNumberToDate(prevRange[0]);
      const endDate = fromNumberToDate(prevRange[1]);

      onValueChange(fieldName, [startDate, endDate], vizId);
      return prevRange; // Return the same range to avoid unnecessary re-renders
    });
  };

  const handleStartDateChange = (event) => {
    const startDate = dateToValue(event);
    // interchange the dates if selected start date is after the end date
    if (moment(event).isBefore(moment(selectedEndDate), "day")) {
      setSelectedStartDate(event);
      setRange([startDate, range[1]]);
    } else {
      setRange([range[1], startDate]);
      setSelectedStartDate(selectedEndDate);
      setSelectedEndDate(event);
    }

    setTimeout(() => {
      handleCommittedChange();
    }, 500);
  };

  const handleEndDateChange = (event) => {
    const endDate = dateToValue(event);
    // interchange the dates if selected end date is before the start date
    if (moment(event).isAfter(moment(selectedStartDate), "day")) {
      setSelectedEndDate(event);
      setRange([range[0], endDate]);
    } else {
      setRange([endDate, range[0]]);
      setSelectedEndDate(selectedStartDate);
      setSelectedStartDate(event);
    }
    setTimeout(() => {
      handleCommittedChange();
    }, 500);
  };

  useEffect(() => {
    setRange([dateToValue(startDate), dateToValue(endDate)]);
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    setTimeout(() => {
      handleCommittedChange();
    }, 500);
  }, [minSelectableDate]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 0, fontSize: "11px", color: "#AF3241" }}>
        <span>Period</span>
      </Box>

      <Slider
        getAriaLabel={() => "Date range"}
        value={range}
        min={dateToValue(startDate)}
        max={dateToValue(endDate)}
        step={1}
        onChange={handleChange}
        onChangeCommitted={handleCommittedChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(values) => {
          const date = fromNumberToDate(values);
          return formatDate(date);
        }}
        sx={{ color: "#AF3241", position: "sticky", marginLeft: "7px" }}
        size="small"
      />

      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <span>
          <DatePicker
            selected={selectedStartDate}
            onChange={handleStartDateChange}
            calendarStartDay={1}
            customInput={
              <Stack direction={"row"} spacing={1}>
                <DateRange sx={{ fontSize: "15px" }} />
                <Typography variant="body2">{formatDateWithoutTimeZoneConversion(selectedStartDate)}</Typography>
              </Stack>
            }
            minDate={startDate}
            maxDate={endDate}
            showWeekNumbers
          />
        </span>
        <span>
          <DatePicker
            selected={selectedEndDate}
            onChange={handleEndDateChange}
            calendarStartDay={1}
            customInput={
              <Stack direction={"row"} spacing={1} sx={{ marginLeft: "62px" }}>
                <DateRange sx={{ fontSize: "15px" }} />
                <Typography variant="body2">{formatDateWithoutTimeZoneConversion(selectedEndDate)}</Typography>
              </Stack>
            }
            minDate={startDate}
            maxDate={endDate}
            showWeekNumbers
          />
        </span>
      </Box>
    </>
  );
};

export default DateRangeFilter;
